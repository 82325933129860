// notificationHelper.js
let notify;

export const setNotification = (notificationFunction) => {
  notify = notificationFunction;
};

export const MakeSucceedNotification = (message) => {
  if (notify) notify(message, 'success');
};

export const MakeFailedNotification = (message) => {
  if (notify) notify(message, 'error');
};
