import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { tokenSymbol } from '../../utils/contractUtils';
import {Typography, CircularProgress, Button, Tooltip, IconButton, Box} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {truncateAddress} from '../../utils/stringUtils';

function WalletForm({
    walletState,
    user,
    loading,
    onConnectWallet,
    onDisconnectWallet,
    onClaimEarnings
}) {
  const { t } = useTranslation();
  const rewardRate = "3%";

  useEffect(() => {
  }, [user]);

  const [copied, setCopied] = React.useState(false);
  const handleCopy = async () => {
    if (walletState.account) {
      await navigator.clipboard.writeText(walletState.account);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    }
  };
  return (
    <div>
      {!walletState.isConnected ? (
        <Button
          variant="contained"
          onClick={onConnectWallet}
          disabled={walletState.isConnecting || loading}
          fullWidth
        >
          {walletState.isConnecting || loading ? <CircularProgress size={24} /> : t("Connect Wallet")}
        </Button>
      ) : (
        <>
            <Typography variant="body2" sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
              {t('Connected Account')}: {truncateAddress(walletState.account)}
              <Tooltip title={copied ? t('Copied!') : t('Copy address')}>
                <IconButton onClick={handleCopy} size="small" sx={{ ml: 1 }}>
                  {copied ? <CheckIcon fontSize="small" color="success" /> : <ContentCopyIcon fontSize="small" />}
                </IconButton>
              </Tooltip>
            </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            {t('Balance')}: {user.balance} {tokenSymbol}
          </Typography>
          {user.affCode && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                <Typography variant="body2">
                  {t('My Affiliate Code')}: {user.affCode}
                </Typography>
                <Tooltip title={`${t('People using your aff code will reward you of user costs')} ${rewardRate}`}>
                  <IconButton size="small" sx={{ ml: 1 }}>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
              <Typography variant="body2" sx={{ mt: 1 }}>
                {t('Earnings')}: {user.affBalance} {tokenSymbol}
              </Typography>
              <Button
                variant="contained"
                onClick={onClaimEarnings}
                sx={{ mt: 1 }}
                disabled={user.affBalanceWei === 0n}
              >
                {t('Claim Earnings')}
              </Button>
            </>
          )}
          <Button
            variant="outlined"
            onClick={onDisconnectWallet}
            sx={{ mt: 2 }}
            fullWidth
          >
            {t('Disconnect Wallet')}
          </Button>
        </>
      )}
      {walletState.error && <Typography color="error" sx={{ mt: 2 }}>{walletState.error}</Typography>}
    </div>
  );
}

export default WalletForm;