// BetForm.js
import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { MakeSucceedNotification, MakeFailedNotification } from '../contexts/notificationHelper';
import { ethers } from 'ethers';

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  marginTop: theme.spacing(2),
  width: '100%', // Make the button full width
}));

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  width: '100%', // Ensure the container takes full width
}));

const BetForm = ({
                  user,
                  contract,
                  tokenSymbol,
                  onSuccessfulBet, 
                  onPendingBet, 
                }) => {
  const { t } = useTranslation();
  const [luckyNumber, setLuckyNumber] = useState('');
  const [amount, setAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [cost, setCost] = useState('');
  const [betPriceUnint, setbetPriceUnint] = useState(0);
  const [invitedCode, setInvitedCode] = useState(user.invitorAffCode);
  const singleBetReward = 1000;

  useEffect(() => {
    setInvitedCode(user.invitorAffCode);
  }, [user]);

  useEffect(() => {
    // 
  }, [contract]);

  useEffect(() => {
    
    const fetchBetPriceUnit = async () => {
      try {
        const betPriceWei = await contract.betPriceUnit();
        setbetPriceUnint(BigInt(betPriceWei));
      } catch (error) {
        console.error("Failed to fetch betPriceUnit:", error);
      }
    };

    fetchBetPriceUnit();
  }, [contract]);

  const handleLuckyNumberChange = async (e) => {
    const value = e.target.value;
    if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 999)) {
      setLuckyNumber(value);
    }
  };
  const handleAmountNumberChange = async (e) => {
    const value = e.target.value;
    // make sure not starts with 0 or '-'
    if (value === '0' || value.startsWith('-')) {
      return;
    }
    setAmount(value);
    if (value !== '') {
      try {
        const costWei = betPriceUnint * BigInt(parseInt(value));
        setCost(ethers.formatEther(costWei));
      } catch (error) {
        console.error('Error calculating cost:', error);
        setCost('');
      }
    } else {
      setCost('');
    }
  };

  const handleAffiliateCodeChange = (e) => {
    setInvitedCode(e.target.value);
  };

  const handleBet = async () => {
    if (amount === 0) {
        MakeFailedNotification(t('Please enter a valid amount'));
        return;
    }
    if (luckyNumber === '') {
        MakeFailedNotification(t('Please enter a lucky number'));
        return;
    }
    const amountWei = BigInt(parseInt(amount)) * betPriceUnint;
    if (amountWei >= user.balanceWei) {
        MakeFailedNotification(t('Insufficient balance'));
        return;
    }
    setIsLoading(true);
    try {
      const luckyNumberInt = parseInt(luckyNumber);
      
      const transaction = await contract.bet(
                                      [luckyNumberInt],
                                      [amount], invitedCode,
                                      { value: amountWei }
                                );

      onPendingBet({ luckyNumber: luckyNumberInt, amount });

      await transaction.wait();
      await user.updateAfterBet(contract);
      onSuccessfulBet();
      MakeSucceedNotification(t('Bet placed successfully!'));
    } catch (error) {
      console.error('Error placing bet:', error);
      MakeFailedNotification(t('Error placing bet. Check console for details.'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormContainer>
      <Typography variant="body2" style={{ marginTop: '8px', marginBottom: '8px' }}>
          {t("Each bet costs")} {ethers.formatEther(betPriceUnint)} {tokenSymbol} {t("The reward for winning a single bet is")} {singleBetReward} {tokenSymbol}
      </Typography>
      <TextField
        fullWidth
        label={t("Lucky Number (0-999)")}
        type="number"
        value={luckyNumber}
        onChange={handleLuckyNumberChange}
        disabled={isLoading}
        inputProps={{
          min: 0,
          max: 999,
        }}
        error={luckyNumber !== '' && (parseInt(luckyNumber) < 0 || parseInt(luckyNumber) > 999)}
        helperText={luckyNumber !== '' && (parseInt(luckyNumber) < 0 || parseInt(luckyNumber) > 999) ? t("Please enter a number between 0 and 999") : ""}
      />
      <TextField
        fullWidth
        label={t("Bet Amount")}
        type="number"
        value={amount}
        onChange={handleAmountNumberChange}
        disabled={isLoading}
        inputProps={{
          min: 0
        }}
      />
      <TextField
        fullWidth
        label={`${t("Cost")} ${tokenSymbol}`}
        value={cost}
        disabled
      />
      {!user.hasBeingInvited ? (
        <>
          <TextField
            fullWidth
            label={t("Affiliate Code (Optional)")}
            onChange={handleAffiliateCodeChange}
            disabled={isLoading}
          />
          <Typography variant="body2" style={{ marginTop: '8px', marginBottom: '8px' }}>
            {t("Use an affiliate code for a 2% betting reward!")}
          </Typography>
        </>
      ) : (
          <>
            <TextField
              fullWidth
              value={`${t("invitor code (can not change)")}: ${user.invitorAffCode}`}
              disabled
            />
            <Typography variant="body2" style={{ marginTop: '8px', marginBottom: '8px' }}>
              {t("Use affiliate code for 2% betting reward")}
          </Typography>
          </>
      )}
      <StyledButton onClick={handleBet} disabled={isLoading}>
        {isLoading ? <CircularProgress size={24} /> : t("Place Bet")}
      </StyledButton>
    </FormContainer>
  );
};

export default BetForm;