import React, { useMemo } from 'react';
import { Container, Grid } from '@mui/material';
import PrizePoolForm from './PrizePoolForm';
import BetForm from './BetForm';
import ClaimPrize from './ClaimPrize';
import PendingBets from './widgets/PendingBets';
import { useTranslation } from 'react-i18next';
import WalletForm from "./widgets/WalletForm";
import { quickContract } from "../utils/contractUtils";
import { StyledPaper, SectionTitle } from './styles';

const MainContent = React.memo(function MainContent({
  walletConnectionProps,
  lotteryInfo,
  contract,
  user,
  refreshTrigger,
  triggerRefresh,
  handlePendingBet,
  pendingBets,
  tokenSymbol,
}) {
  const { t } = useTranslation();
  const memoizedBetForm = useMemo(() => (
    contract && user.isConnected && (
      <div>
        <BetForm
          contract={contract}
          user={user}
          tokenSymbol={tokenSymbol}
          onSuccessfulBet={triggerRefresh}
          onPendingBet={handlePendingBet}
        />
        <PendingBets pendingBets={pendingBets} />
      </div>
    )
  ), [contract, user, tokenSymbol, triggerRefresh, handlePendingBet, pendingBets]);

  const memoizedClaimPrize = useMemo(() => (
    contract && user.isConnected && (
      <ClaimPrize
        contract={contract}
        account={user.account}
        refreshTrigger={refreshTrigger}
        tokenSymbol={tokenSymbol}
      />
    )
  ), [contract, user, refreshTrigger, tokenSymbol]);
  return (
    <Container maxWidth="lg" sx={{ mt: 6, mb: 6 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <StyledPaper elevation={3}>
            {/*<SectionTitle variant="h5">{t("Account Information")}</SectionTitle>*/}
            <WalletForm {...walletConnectionProps} />
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={6}>
          <StyledPaper elevation={3}>
            <SectionTitle variant="h5">{t("Reward Pool")}</SectionTitle>
            {quickContract && <PrizePoolForm {...lotteryInfo} />}
          </StyledPaper>
        </Grid>

        {user.isConnected && (
          <>
            <Grid item xs={12} md={6}>
              <StyledPaper elevation={3}>
                <SectionTitle variant="h5">{t("Betting")}</SectionTitle>
                {memoizedBetForm}
              </StyledPaper>
            </Grid>

            <Grid item xs={12} md={6}>
              <StyledPaper elevation={3}>
                <SectionTitle variant="h5">{t("Bet History")}</SectionTitle>
                {memoizedClaimPrize}
              </StyledPaper>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
});

export default MainContent;