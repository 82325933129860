import { ethers } from 'ethers';
import * as lotteryService from './services/lotteryService';
import User from "./models/User";
import { MakeSucceedNotification, MakeFailedNotification } from './contexts/notificationHelper';
import {CHAIN_ID, IS_DEBUG, RPC_URL} from './utils/contractUtils';


const handleWalletError = (error, setWalletState) => {
  console.error('Wallet error:', error);
  setWalletState(prev => ({...prev, error: error.message, isConnecting: false}));
  MakeFailedNotification(`Wallet error: ${error.message}`);
};


const switchToHardhatNetwork = async () => {
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: CHAIN_ID }],
    });
  } catch (switchError) {
    if (switchError.code === 4902) {
      const userConfirmed = window.confirm(
        "This app requires the Polygon network. Would you like to add it to your wallet?"
      );
      if (userConfirmed) {
        await addHardhatNetwork();
      } else {
        throw new Error('User rejected adding Hardhat network');
      }
    } else {
      throw switchError;
    }
  }
};

const addHardhatNetwork = async () => {
  let chainName, symbol;
  if (IS_DEBUG) {
    chainName = 'Hardhat Network';
    symbol = "ETH";
  } else {
    chainName = 'Polygon';
    symbol = "POL";
  }
  try {
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [{
        chainId: CHAIN_ID,
        chainName: chainName,
        nativeCurrency: {
          name: symbol,
          symbol: symbol,
          decimals: 18
        },
        rpcUrls: [RPC_URL],
        blockExplorerUrls: null
      }],
    });
  } catch (addError) {
    throw new Error('Failed to add network to MetaMask');
  }
};

export const connectWallet = async (setWalletState, setContract, setUser, setLoading) => {
  try {
    setLoading(true);

    if (typeof window.ethereum === 'undefined') {
      throw new Error('MetaMask is not installed!');
    }

    const provider = new ethers.BrowserProvider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    const signer = await provider.getSigner();
    const address = await signer.address;

    const account = address;
    
    
    const chainId = await window.ethereum.request({method: 'eth_chainId'});
    

    if (chainId !== CHAIN_ID) {
      await switchToHardhatNetwork();
    }

    
    const lotteryContract = await lotteryService.getLotteryContract(provider, signer);
    
    const user = new User(signer, account, '0', '0', '0', '0', true);
    

    setWalletState({
      isConnected: true,
      isConnecting: false,
      error: null,
      account: account,
      chainId: chainId
    });
    setContract(lotteryContract);
    await user.updateAfterBet(lotteryContract);
    setUser(user);
  } catch (error) {
    handleWalletError(error, setWalletState);
  } finally {
    setLoading(false);
  }
};
export const disconnectWallet = (setWalletState, setContract, setUser) => {
  setWalletState({
    isConnected: false,
    isConnecting: false,
    error: null,
    account: null,
    chainId: null
  });
  setContract(null);
  setUser(new User());
  localStorage.removeItem('walletState');
  localStorage.removeItem('user');

  MakeSucceedNotification('Wallet disconnected successfully!');
};

export const claimEarnings = async (contract, user, setUser, triggerRefresh) => {
  try {
    const tx = await contract.claimAffiliateFee();
    await tx.wait();
    MakeSucceedNotification('Earnings claimed successfully!');
    const updatedUser = await user.updateAfterClaimAffiliateFee(contract);
    setUser({ ...updatedUser, connected: true });
    triggerRefresh();
  } catch (error) {
    console.error('Error claiming earnings:', error);
    MakeFailedNotification(String(error));
  }
};
