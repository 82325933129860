import React from 'react';
import { useTranslation } from 'react-i18next';
import { List, ListItem, ListItemText, CircularProgress } from '@mui/material';

const PendingBets = ({ pendingBets }) => {
  const { t } = useTranslation();

  return (
    <div>
      {pendingBets.length > 0 && (
        <List>
          {pendingBets.map((bet, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={
                  <>
                    {t("Bet Number")}: {bet.luckyNumber}  {t("Bet Amount")}: {bet.amount}
                  </>
                }
              />
              <CircularProgress size={24} />
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
};

export default PendingBets;