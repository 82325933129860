import { ethers } from 'ethers';
// Import your contract ABI
import LotteryContractABI from '../contracts/LotteryBet.sol/LotteryContract.json';
import ContractAddress from '../contracts/LotteryBet.sol/contract_address.json';


export const CONTRACT_ADDRESS = ContractAddress.address;
export const IS_DEBUG = ContractAddress.isDebug;
const network = ContractAddress.network;
export const CHAIN_ID = network.chainId;
export const tokenSymbol = ContractAddress.token_symbol;
export const deployedBlock = parseInt(ContractAddress.deploy_block);
export const RPC_URL = ContractAddress.rpc_url;
export const quickContract = new ethers.Contract(CONTRACT_ADDRESS, LotteryContractABI.abi, new ethers.JsonRpcProvider(RPC_URL));



export const getProvider = async () => {
  const provider = new ethers.JsonRpcProvider(RPC_URL);
  return { provider};
};



export const getContract = async (provider, signer) => {
  // const provider = new ethers.JsonRpcProvider(RPC_URL);
  // const signer = await provider.getSigner(0);
  const contract = new ethers.Contract(CONTRACT_ADDRESS, LotteryContractABI.abi, signer);
  return { contract, signer };
};


export const getAccounts = async () => {
  const provider = new ethers.JsonRpcProvider(RPC_URL);
  const signers = await provider.listAccounts();
  const accounts = await Promise.all(signers.map(async (signer) => await signer.getAddress()));
  return accounts;
};

export const switchAccount = async (accountIndex) => {
  try {
    
    const provider = new ethers.JsonRpcProvider(RPC_URL);
    const signers = await provider.listAccounts();
    if (accountIndex >= signers.length) {
      throw new Error(`Account index ${accountIndex} is out of range`);
    }
    const signer = signers[accountIndex];
    const contract = new ethers.Contract(CONTRACT_ADDRESS, LotteryContractABI.abi, signer);
    return { contract, signer };
  } catch (error) {
    console.error('Error in switchAccount:', error);
    throw error;
  }
};