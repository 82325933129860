import { useState, useEffect, useCallback, useRef } from 'react';
import { ethers } from 'ethers';
import * as lotteryService from "../services/lotteryService";
import {NUMBER_UNSET} from "../services/lotteryService";

function useLotteryInfo(contract, refreshTrigger) {
  const [lotteryInfo, setLotteryInfo] = useState({
    currentRound: '',
    initialRemainingTime: 0,
    prizePool: '',
    lastRoundLuckyNumber: NUMBER_UNSET,
    bettingVolume: ''
  });

  const timeoutIdRef = useRef(null);

  const fetchLotteryInfo = useCallback(async () => {
    if (!contract) {
      return;
    }
    
    try {
      const [round, time, pool, volume] = await Promise.all([
        contract.currentRound(),
        contract.getRemainingBettingTime(),
        contract.getPrizePoolBalance(),
        contract.bettingVolume()
      ]);
      const remainingTime = parseInt(time.toString(), 10);
      const number = await lotteryService.fetchWinningNumberByRound(round-BigInt(1));
      setLotteryInfo({
        currentRound: round.toString(),
        initialRemainingTime: parseInt(remainingTime, 10),
        prizePool: ethers.formatEther(pool),
        lastRoundLuckyNumber: number,
        bettingVolume: volume.toString()
      });
      const nextFetchDelay = Math.max(remainingTime * 1000, 10000);
      timeoutIdRef.current = setTimeout(fetchLotteryInfo, nextFetchDelay);
    } catch (error) {
      console.error('Error fetching lottery info:', error);
      timeoutIdRef.current = setTimeout(fetchLotteryInfo, 5000);
    }
  }, [contract]);

  useEffect(() => {
    fetchLotteryInfo();
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, [fetchLotteryInfo, refreshTrigger]);

  return lotteryInfo;
}

export default useLotteryInfo;