import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress } from '@mui/material';
import * as lotteryService from '../services/lotteryService';
import { MakeSucceedNotification, MakeFailedNotification } from '../contexts/notificationHelper';
import BettingHistoryList from './widgets/BettingHistoryList';
import {quickContract} from "../utils/contractUtils";

const ClaimPrize = ({ contract, account, refreshTrigger, tokenSymbol }) => {
  const { t } = useTranslation();
  const [bettingHistory, setBettingHistory] = useState([]);
  const [winningNumbers, setWinningNumbers] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    if (contract && account) {
      setLoading(true);
      try {
        const bets = await lotteryService.fetchBettingHistory(quickContract, account);
        const winningNums = await lotteryService.fetchWinningNumbers(quickContract);
        const claimedPrizes = await lotteryService.fetchClaimedPrizes(quickContract, account);

        const updatedBets = bets.map(bet => ({
          ...bet,
          has_published: winningNums.hasOwnProperty(bet.round.toString()),
          claimed: claimedPrizes.some(prize =>
            prize.roundNumber.toString() === bet.round.toString() &&
            prize.luckyNumber.toString() === bet.luckyNumber.toString()
          )
        }));

        setBettingHistory(updatedBets);
        setWinningNumbers(winningNums);
      } catch (error) {
        console.error('Error fetching data:', error);
        MakeFailedNotification(t('Error fetching data. Check console for details.'));
      } finally {
        setLoading(false);
      }
    }
  }, [contract, account, t]);

  useEffect(() => {
    fetchData();
  }, [fetchData, refreshTrigger]); // Add refreshTrigger to the dependency array

  const handleClaim = async (selectedBet) => {
    try {
      setLoading(true);
      let round = parseInt(selectedBet.round);
      let luckyNumber = parseInt(selectedBet.luckyNumber);
      let betCount = parseInt(selectedBet.amount);
      const data = {
        "wallet": account,
        "roundNumber": round,
        "luckyNumber": luckyNumber,
        "amount": betCount
      }
      const signature = await lotteryService.getSignatureForClaim(data);

      await lotteryService.claimPrize(
        contract,
        round,
        luckyNumber,
        betCount,
        signature
      );

      MakeSucceedNotification(t('Prize claimed successfully!'));
      setBettingHistory(prevHistory =>
        prevHistory.map(bet =>
          bet.round === selectedBet.round ? { ...bet, claimed: true } : bet
        )
      );
    } catch (error) {
      console.error('Error claiming prize:', error);
      const errorMessage = error instanceof Error ? error.message : String(error);
      MakeFailedNotification(`${t('Error claiming prize')}: ${errorMessage}`);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <BettingHistoryList 
        bettingHistory={bettingHistory}
        winningNumbers={winningNumbers}
        onClaim={handleClaim}
        tokenSymbol={tokenSymbol}
      />
    </Box>
  );
};

export default ClaimPrize;