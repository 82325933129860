import axios from 'axios';
import { ethers } from 'ethers';
import {getContract, quickContract} from '../utils/contractUtils';
import { getProvider, deployedBlock } from '../utils/contractUtils';

export const getLotteryContract = async (provider, signer) => {
  const { contract } = await getContract(provider, signer);
  return contract;
};


export const getAccountBalance = async (address) => {
  const { provider } = await getProvider();
  return await provider.getBalance(address);
};

export const fetchInvitedCode = async (contract, account) => {
try {
    const filter = contract.filters.AffiliateBet(account);

    const events = await contract.queryFilter(filter, deployedBlock, 'latest');

    if (events.length > 0) {
      const invitorCode = events[0].args.invitorCode;
      
      return invitorCode;
    }
    
    return 0;
  } catch (error) {
    console.error("Error fetching invited code:", error);
    throw error;
  }
};

export const fetchBettingHistory = async (contract, account) => {
  const filter = contract.filters.NewBet(account);
  const events = await contract.queryFilter(filter, deployedBlock, 'latest');
  return events.map(event => ({
    round: event.args.roundNumber.toString(),
    luckyNumber: event.args.luckyNumbers[0].toString(),
    amount: event.args.totalAmount.toString(),
    value: ethers.formatEther(event.args.value),
    timestamp: new Date(Number(event.args.timestamp) * 1000).toLocaleString(),
    txHash: event.transactionHash,
  })).reverse();
};

export const NUMBER_UNSET = -1;
export const fetchWinningNumberByRound = async (roundNumber) => {
  
  const filter = quickContract.filters.WinningNumberSet(null, roundNumber);
  const events = await quickContract.queryFilter(filter, deployedBlock, 'latest');
  if (events.length === 0) {
    return NUMBER_UNSET;
  } else
    return events[0].args.winningNumber.toString();
};



export const fetchWinningNumbers = async (contract) => {
  const filter = contract.filters.WinningNumberSet();
  const events = await contract.queryFilter(filter, deployedBlock, 'latest');
  return events.reduce((acc, event) => {
    acc[event.args.roundNumber.toString()] = event.args.winningNumber.toString();
    return acc;
  }, {});
};

export const fetchClaimedPrizes = async (contract, account) => {
  const filter = contract.filters.PrizeClaimed(account);
  const events = await contract.queryFilter(filter, deployedBlock, 'latest');
  return events.map(event => ({
    roundNumber: event.args.roundNumber.toString(),
    luckyNumber: event.args.luckyNumber.toString(),
  }));
};

export const claimPrize = async (contract, round, luckyNumber, betCount, signature) => {
  const tx = await contract.claimPrize(round, luckyNumber, betCount, signature);
  await tx.wait();
};


// Create an axios instance with a base URL
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || window.location.origin,
});

export const getSignatureForClaim = async (betInfo) => {
  try {
    const response = await api.post('/api/getSignature', betInfo);

    if (response.data && response.data.is_ok && response.data.signature) {
      // Decode the base64 signature
      const binaryString = atob(response.data.signature);
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return '0x' + Array.from(bytes, b => b.toString(16).padStart(2, '0')).join('');
    } else if (response.data && !response.data.is_ok && response.data.error) {
      throw new Error(response.data.error);
    } else {
      throw new Error('Invalid response from signature server');
    }
  } catch (error) {
    console.error('Error getting signature:', error);
    throw error;
  }
};
