import './i18n';
import React, { useState, useEffect, useCallback } from 'react';
import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {quickContract, tokenSymbol} from './utils/contractUtils';
import { NotificationProvider, useNotification } from './contexts/NotificationContext';
import { setNotification } from './contexts/notificationHelper';
import AppHeader from './components/widgets/AppHeader';
import MainContent from './components/MainContent';
import useLotteryInfo from './hooks/useLotteryInfo';
import ThemeContext from './contexts/ThemeContext';
import User from "./models/User";
import { connectWallet, disconnectWallet, claimEarnings } from './walletConnector';


const serializeUser = (user) => {
  return JSON.stringify(user, (key, value) =>
    typeof value === 'bigint' ? value.toString() : value
  );
};

const deserializeUser = (userString) => {
  return JSON.parse(userString, (key, value) => {
    if (key === 'balanceWei' || key === 'affBalanceWei') {
      return BigInt(value);
    }
    return value;
  });
};

function MainApp() {
  const { i18n } = useTranslation();
  const [contract, setContract] = useState(null);
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem('user');
    return savedUser ? deserializeUser(savedUser) : new User();
  });
  const [walletState, setWalletState] = useState({
    isConnected: false,
    isConnecting: false,
    error: null,
    account: null,
    chainId: null
  });
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const lotteryInfo = useLotteryInfo(quickContract, refreshTrigger);
  const [pendingBets, setPendingBets] = useState([]);

  const notify = useNotification();

  const handlePendingBet = (bet) => {
    setPendingBets([...pendingBets, bet]);
    
  };

  useEffect(() => {
    document.title = t("Lottery DApp");
  }, [t]);

  useEffect(() => {
    setNotification(notify);
  }, [notify]);

  useEffect(() => {
    localStorage.setItem('user', serializeUser(user));
  }, [user]);

  useEffect(() => {
    const savedWalletState = JSON.parse(localStorage.getItem('walletState'));
    if (savedWalletState && savedWalletState.isConnected) {
      handleConnectWallet();
    }
  }, []);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', (accounts) => {
        if (accounts.length > 0) {
          handleConnectWallet();
        } else {
          handleDisconnectWallet();
        }
      });

      window.ethereum.on('chainChanged', () => {
        handleConnectWallet();
      });

      return () => {
        window.ethereum.removeAllListeners('accountsChanged');
        window.ethereum.removeAllListeners('chainChanged');
      };
    }
  }, []);

  useEffect(() => {
    if (walletState.isConnected && walletState.account) {
      localStorage.setItem('walletState', JSON.stringify({
        isConnected: true,
        account: walletState.account,
        chainId: walletState.chainId
      }));
    } else {
      localStorage.removeItem('walletState');
    }
  }, [walletState]);

  const triggerRefresh = useCallback(() => {
    setPendingBets([]);
    setRefreshTrigger(prev => prev + 1);
  }, []);

const handleConnectWallet = async () => {
  setLoading(true);
  try {
    await connectWallet(setWalletState, setContract, setUser, setLoading);
    
    
    
  } catch (error) {
    console.error("Error connecting wallet:", error);
    setNotification({
      type: 'error',
      message: 'Failed to connect wallet. Please try again.'
    });
  } finally {
    setLoading(false);
  }
};


  const handleDisconnectWallet = () => {
    disconnectWallet(setWalletState, setContract, setUser);
  };

  const handleClaimEarning = async () => {
    setLoading(true);
    try {
      await claimEarnings(contract, user, setUser, triggerRefresh);
    } catch (error) {
      console.error("Error claiming earnings:", error);
      setNotification({
        type: 'error',
        message: 'Failed to claim earnings. Please try again.'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleLanguageChange = (newLang) => {
    i18n.changeLanguage(newLang);
  };

  const walletConnectionProps = {
    user,
    walletState,
    loading,
    onConnectWallet: handleConnectWallet,
    onDisconnectWallet: handleDisconnectWallet,
    onClaimEarnings: handleClaimEarning
  };

  return (
    <Container maxWidth="lg">
      <AppHeader onLanguageChange={handleLanguageChange} />
      <MainContent
        walletConnectionProps={walletConnectionProps}
        lotteryInfo={lotteryInfo}
        contract={contract}
        user={user}
        refreshTrigger={refreshTrigger}
        triggerRefresh={triggerRefresh}
        handlePendingBet={handlePendingBet}
        pendingBets={pendingBets}
        tokenSymbol={tokenSymbol}
      />
    </Container>
  );
}

function App() {
  return (
    <ThemeContext>
      <NotificationProvider>
        <MainApp />
      </NotificationProvider>
    </ThemeContext>
  );
}

export default App;