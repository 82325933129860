import React from 'react';
import {List, ListItem, ListItemText, Button, Typography, Box, IconButton, Tooltip} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {OpenInNew} from "@mui/icons-material";

const BettingHistoryList = ({ bettingHistory, winningNumbers, onClaim, tokenSymbol}) => {
  const { t } = useTranslation();
  const networkExplorer = process.env.REACT_APP_NETWORK_EXPLORER;

const handleViewTransaction = (event) => {
  const index = event.currentTarget.value;
  
  const bet = bettingHistory[index];
  
  window.open(`${networkExplorer}/tx/${bet.txHash}`, '_blank');
};

  return (
    <Box sx={{ height: '60vh', overflowY: 'auto' }}>
      {bettingHistory.length > 0 ? (
        <List>
          {bettingHistory.map((bet, index) => (
            <ListItem
                key={index}
                divider
               secondaryAction={
                  <Tooltip title={t("View Transaction")}>
                    <IconButton
                      edge="end"
                      aria-label="view transaction"
                      onClick={handleViewTransaction}
                      value={index}
                    >
                      <OpenInNew />
                    </IconButton>
                  </Tooltip>
              }
              >
              <Box sx={{ width: '100%' }}>
                <ListItemText 
                  primary={`${t('round')} ${bet.round}: ${t('Bet Number')} ${bet.luckyNumber}`}
                  secondary={
                    <>
                      <Typography component="span" variant="body2" color="text.primary">
                        {`${t('Bet Amount')}: ${bet.amount}`}
                      </Typography>
                      <br />
                      <Typography component="span" variant="body2" color="text.secondary">
                        {`${t('Bet Time')}: ${new Date(bet.timestamp).toLocaleString()}`}
                      </Typography>
                    </>
                  }
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                <Typography variant="body2" color="text.secondary">
                {`${t('Cost')}: ${bet.value} ${tokenSymbol}`}
                  </Typography>
                  {!bet.has_published ? (
                    <Typography color="text.secondary">{t('Waiting the draw')}</Typography>
                  ) : winningNumbers[bet.round] === bet.luckyNumber ? (
                    bet.claimed ? (
                      <Typography color="text.secondary">{t('claimed')}</Typography>
                    ) : (
                      <Button 
                        onClick={() => onClaim(bet)} 
                        variant="contained" 
                        color="primary"
                        size="small"
                      >
                        {t('claim')}
                      </Button>
                    )
                  ) : (
                    <Typography color="text.secondary">{t('not_won')}</Typography>
                  )}
            </Box>
            </Box>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography>{t('no_betting_history')}</Typography>
      )}
    </Box>
  );
};

export default BettingHistoryList;