import React, { useState, useEffect } from 'react';
import { CircularProgress, Grid, Tooltip, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { tokenSymbol, CONTRACT_ADDRESS } from '../utils/contractUtils';
import { NUMBER_UNSET } from "../services/lotteryService";
import { StyledBox, StyledGrid, Label, Value, LoadingBox } from './styles';
import {truncateAddress} from '../utils/stringUtils';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


const PrizePoolForm = ({ currentRound, initialRemainingTime, prizePool, lastRoundLuckyNumber, bettingVolume }) => {
  const { t } = useTranslation();
  const [remainingTime, setRemainingTime] = useState(initialRemainingTime);
  const [isLoading, setIsLoading] = useState(true);
  const [copied, setCopied] = useState(false);
  
  const handleCopy = () => {
    navigator.clipboard.writeText(CONTRACT_ADDRESS);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };
  

  useEffect(() => {
    if (currentRound !== '' && initialRemainingTime > 0 && prizePool !== '') {
      setIsLoading(false);
    }
  }, [currentRound, initialRemainingTime, prizePool]);

  useEffect(() => {
    setRemainingTime(initialRemainingTime);
  }, [initialRemainingTime]);

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime(prevTime => prevTime > 0 ? prevTime - 1 : 0);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return [hours, minutes, remainingSeconds]
      .map(v => v < 10 ? "0" + v : v)
      .join(":");
  };

  const luckyNumberStr = lastRoundLuckyNumber === NUMBER_UNSET ? t('Waiting the draw') : lastRoundLuckyNumber.toString().padStart(3, '0');

  if (isLoading) {
    return (
      <LoadingBox>
        <CircularProgress />
      </LoadingBox>
    );
  }

  return (
    <StyledBox>
      <Grid container spacing={2}>
        <StyledGrid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Label>{t('Current Round')}:</Label>
            </Grid>
            <Grid item xs={6}>
              <Value>{currentRound}</Value>
            </Grid>
          </Grid>
        </StyledGrid>
        <StyledGrid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Label>{t('Last Round Lucky Number')}:</Label>
            </Grid>
            <Grid item xs={6}>
              <Value>{luckyNumberStr}</Value>
            </Grid>
          </Grid>
        </StyledGrid>
        <StyledGrid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Label>{t('Remaining Betting Time')}:</Label>
            </Grid>
            <Grid item xs={6}>
              <Value>{formatTime(remainingTime)}</Value>
            </Grid>
          </Grid>
        </StyledGrid>
        <StyledGrid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Label>{t('Prize Pool')}:</Label>
            </Grid>
            <Grid item xs={6}>
              <Value>{prizePool} {tokenSymbol}</Value>
            </Grid>
          </Grid>
        </StyledGrid>
        <StyledGrid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Label>{t('History Betting Count')}:</Label>
            </Grid>
            <Grid item xs={6}>
              <Value>{bettingVolume}</Value>
            </Grid>
          </Grid>
        </StyledGrid>
        <StyledGrid item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Label>{t('Contract Address')}:</Label>
            </Grid>
            <Grid item xs={5}>
              <Value>{truncateAddress(CONTRACT_ADDRESS)}</Value>
            </Grid>
            <Grid item xs={1}>
              <Tooltip title={copied ? t('Copied!') : t('Copy to clipboard')} placement="top">
                <IconButton onClick={handleCopy} size="small">
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </StyledGrid>
      </Grid>
    </StyledBox>
  );
};

export default PrizePoolForm;