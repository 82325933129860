// src/components/widgets/AppHeader.js
import React, { useContext, useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, useTheme, Menu, MenuItem } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import LanguageIcon from '@mui/icons-material/Language';
import { useTranslation } from 'react-i18next';
import { ColorModeContext } from '../../contexts/ThemeContext';

function AppHeader({ onLanguageChange }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageSelect = (lang) => {
    onLanguageChange(lang);
    handleMenuClose();
  };

  return (
    <AppBar position="static" color="primary" elevation={0}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {t('Lottery DApp')}
        </Typography>
        <IconButton onClick={handleMenuOpen} color="inherit">
          <LanguageIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => handleLanguageSelect('en')}>English</MenuItem>
          <MenuItem onClick={() => handleLanguageSelect('zh')}>中文</MenuItem>
        </Menu>
        <IconButton onClick={colorMode.toggleColorMode} color="inherit">
          {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

export default AppHeader;