import { ethers } from "ethers";
import * as lotteryService from '../services/lotteryService';

class User {
  constructor(signer=null, account = '', balanceWei = '0', affCode = '0', invitorAffCode = '0', affBalanceWei = '0', connected=false) {
    this.signer = signer;
    this.isConnected = connected;
    this.account = account;
    this.balanceWei = balanceWei;
    this.affCode = affCode;
    this.invitorAffCode = invitorAffCode;
    this.affBalanceWei = affBalanceWei;
  }

  get balance() {
    return ethers.formatEther(this.balanceWei);
  }

  get affBalance() {
    return ethers.formatEther(this.affBalanceWei);
  }

  get hasBeingInvited() {
    return this.invitorAffCode !== '0';
  }

  update({ account, balanceWei, affCode, invitorAffCode, affBalanceWei }) {
    if (account !== undefined) this.account = account;
    if (balanceWei !== undefined) this.balanceWei = balanceWei;
    if (affCode !== undefined) this.affCode = affCode;
    if (invitorAffCode !== undefined) this.invitorAffCode = invitorAffCode;
    if (affBalanceWei !== undefined) this.affBalanceWei = affBalanceWei;
  }

  async updateBalance(contract) {
    try {
      const newBalanceWei = await lotteryService.getAccountBalance(this.account);
      this.balanceWei = newBalanceWei;
      return this.balance;
    } catch (error) {
      console.error("Error updating balance:", error);
      throw error;
    }
  }

  async updateAfterClaimAffiliateFee(contract) {
    try {
      this.affBalanceWei = await contract.getAffiliateBalance(this.account);
      await this.updateBalance(contract);
      return this.affBalance;
    } catch (error) {
      console.error("Error updating affiliate balance after claiming:", error);
      throw error;
    }
  }

  async updateAfterBet(contract) {
    try {
      const [
        newBalanceWei,
        newAffCode,
        newInvitorAffCode,
        newAffBalanceWei
      ] = await Promise.all([
        lotteryService.getAccountBalance(this.account),
        contract.getAffiliateCode(this.account),
        lotteryService.fetchInvitedCode(contract, this.account),
        contract.getAffiliateBalance(this.account)
      ]);

      this.balanceWei = newBalanceWei;
      this.affCode = newAffCode.toString();
      this.invitorAffCode = newInvitorAffCode.toString();
      this.affBalanceWei = newAffBalanceWei;
      return {
        balance: this.balance,
        affCode: this.affCode,
        invitorAffCode: this.invitorAffCode,
        affBalance: this.affBalance
      };
    } catch (error) {
      console.error("Error updating user data after bet:", error);
      throw error;
    }
  }
}

export default User;